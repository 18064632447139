import React, { useState } from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import './SearchComponent.css'; 
import { Button } from 'react-bootstrap';

const SearchComponent = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleSuggestionsFetchRequested = async ({ value }) => {
    if (value) {
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
          params: {
            q: value,
            format: 'json',
            addressdetails: 1,
            limit: 5
          }
        });
        setSuggestions(response.data);
      } catch (error) {
        console.error(error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.display_name;

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.display_name}
    </div>
  );

  const handleSuggestionSelected = (event, { suggestion }) => {
    const { lat, lon } = suggestion;
    const coordinates = [parseFloat(lon), parseFloat(lat)];
    onSearch(coordinates);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    if (searchQuery) {
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
          params: {
            q: searchQuery,
            format: 'json',
            addressdetails: 1,
            limit: 1
          }
        });

        if (response.data.length > 0) {
          const { lat, lon } = response.data[0];
          onSearch([parseFloat(lon), parseFloat(lat)]);
        } else {
          console.error('No results found');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const inputProps = {
    placeholder: 'Search Place',
    value: searchQuery,
    onChange: (e, { newValue }) => setSearchQuery(newValue)
  };

  return (
    <form onSubmit={handleSearch}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={handleSuggestionSelected}
        inputProps={inputProps}
      />
      <Button variant="primary" type="submit" style={{marginTop: '5px'}}>
        Search
      </Button>
    </form>
  );
};

export default SearchComponent;
