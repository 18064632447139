import React, { useEffect, memo } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


import 'ol/ol.css';
import './App.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignInForm from './components/Signin/Signin';
import SignUpForm from './components/Signup/Signup';
import { jwtDecode } from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import Home from './pages/Home';
import ProtectedRoute from './components/ProtectedRoute';
import { setToken } from './redux/actions/authActions';
import { notify } from './utils/notify';

const App = () => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated)
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if(!isAuthenticated && token) {
      try {
        const decodedToken = jwtDecode(token);
        const isExpired = decodedToken.exp * 1000 < Date.now();
        if(!isExpired) {
          dispatch(setToken(token));
        } else {
          notify('Token expired, Please login', 'warning');
        }
      } catch (error) {
        console.log('Error while decoding token')
      }
    }
  },[])

 
   useEffect(() => {
    const handleError = (e) => {
      if (e.message.includes('ResizeObserver loop')) {
        console.warn('ResizeObserver loop limit exceeded error ignored.');
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');

        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none';
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.style.display = 'none';
        }
      }
    };
    window.addEventListener('error', handleError);
    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);
 

  
  return (
    <Router>
      <ToastContainer />
      <Routes>
        {/* If need to render protected route without navbar then create another protected Route group with 'withNavbar value false' */}
        <Route element={<ProtectedRoute withNavbar />}>
          {/* <Route element={<Navbar />} */}
          <Route path="/" element={<Home />} />
        </Route>
        {/* <Route path="/" element={
          <ProtectedRoute element={
            isAuthenticated ? (
              <>
                <Toolbar />
                <div id="map" className="map"></div>
                <Dialog open="" onClose="" maxWidth="lg" fullWidth>
                  <DialogTitle>Data Table</DialogTitle>
                  <DialogContent>
                    <DataTable />
                  </DialogContent>
                </Dialog>
                <Card style={{ width: '18rem', top: '5em', left: '0.5em' }}>
                  <Card.Body>
                    <Card.Title>Search</Card.Title>
                    <SearchComponent onSearch={handleSearch} />
                  </Card.Body>
                </Card>
                <DrawerComponent
                  isOpen=""
                  onClose={handleReset}
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                  selectedCoordinates={selectedCoordinates}
                />
                <ToastContainer />
              </>
            ) : ''
          }/>
        } /> */}
        <Route path="/sign-in" element={<SignInForm />} />
        <Route path="/sign-up" element={<SignUpForm />} />
      </Routes>
    </Router>
  );
};

export default memo(App);
