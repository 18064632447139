import React from 'react';
import { Drawer, Box, Typography, TextField, MenuItem, Button, IconButton, Grid, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setDrawerOpen } from '../../redux/actions/drawerActions';

import 'react-toastify/dist/ReactToastify.css';


const DrawerComponent = ({ isOpen, onClose, onSubmit, onReset }) => {
  const isDrawerOpen = useSelector((state) => state.drawer?.isDrawerOpen);
  const accessToken = useSelector((state) => state.auth?.accessToken);
  const selectedCoordinates = useSelector( state => state.drawer?.selectedCoordinates);


  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    phone: '',
    type: '',
    treatyNumber: '',
    duration: '',
    date: null,
    habitat: '',
    observation: '',
    species: '',
    quantity: '',
    condition: '',
    latitude: selectedCoordinates ? selectedCoordinates[1] : '',
    longitude: selectedCoordinates ? selectedCoordinates[0] : '',
  });

  React.useEffect(() => {
    if (selectedCoordinates) {
      setFormData(prevFormData => ({
        ...prevFormData,
        latitude: selectedCoordinates[1],
        longitude: selectedCoordinates[0],
      }));
    }
  }, [selectedCoordinates]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleDateChange = (date) => {
    setFormData(prevFormData => ({ ...prevFormData, date: date ? date.toISOString().split('T')[0] : null }));
  };

  const validateForm = () => {
    let isValid = true;
    let tempErrors = {};

    if (!formData.type) {
      tempErrors.type = "Type is required.";
      isValid = false;
    }

    // if (!formData.latitude || isNaN(formData.latitude)) {
    //   tempErrors.latitude = "Valid latitude is required.";
    //   isValid = false;
    // }
    // if (!formData.longitude || isNaN(formData.longitude)) {
    //   tempErrors.longitude = "Valid longitude is required.";
    //   isValid = false;
    // }

    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Invalid email address.";
      isValid = false;
    }

    if (formData.phone && !/^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(formData.phone)) {
      tempErrors.phone = "Invalid phone number.";
      isValid = false;
    }

    if (formData.quantity && isNaN(formData.quantity)) {
      tempErrors.quantity = "Quantity should be a number.";
      isValid = false;
    }

    return { isValid, tempErrors };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { isValid, tempErrors } = validateForm();

    if (!isValid) {
      for (const [key, error] of Object.entries(tempErrors)) {
        toast.error(error);
      }
      return;
    }

    const dataToSend = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      treaty_number: formData.treatyNumber,
      type: formData.type || null,
      duration: formData.duration || null,
      date: formData.date || null,
      habitat: formData.habitat || null,
      observation: formData.observation || null,
      species: formData.species || null,
      quantity: formData.quantity ? parseInt(formData.quantity, 10) : null,
      condition: formData.condition || null,
      latitude: formData.latitude || null,
      longitude: formData.longitude || null
    };

    try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/create-reserve/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(dataToSend),
      }
    );

      const body = await response.json();

      if (response.status >= 200 && response.status < 300) {
        toast.success('Form submitted successfully!');
        console.log('Success:', body);
        onReset();
        // onSubmit(body);
      } else if (response.status === 401) {
        toast.error('Session expired. Please log in again.');
        navigate('/sign-in');
      } else {
        if (body && body.treaty_number) {
          toast.error(body.treaty_number.join(' '));
        } else {
          toast.error('Form submission failed!');
        }
      }
    }
    catch (error) {
          toast.error('Form submission failed!');
          console.error('Error:', error);
        }

    onReset();
    onClose();
  };

  const handleReset = () => {
    setFormData({
      name: '',
      email: '',
      phone: '',
      type: '',
      treatyNumber: '',
      duration: '',
      date: null,
      habitat: '',
      observation: '',
      species: '',
      quantity: '',
      condition: '',
      latitude: '',
      longitude: '',
    });
    onReset();
    onClose();
  };

  const closeDrawer = () => {
    dispatch(setDrawerOpen(false))
}
  return (
     <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={closeDrawer}
      PaperProps={{ style: { width: isSmallScreen ? '50%' : '40%' } }} 
    >
      <Box p={2} width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add Reserve</Typography>
          <IconButton onClick={onClose}>
            {/* <CloseIcon /> */}
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Treaty Number"
                name="treatyNumber"
                value={formData.treatyNumber}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Duration"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Type"
                name="type"
                value={formData.type}
                onChange={handleChange}
                select
                fullWidth
                margin="normal"
                required
              >
                <MenuItem value="hunting">Hunting</MenuItem>
                <MenuItem value="fishing">Fishing</MenuItem>
                <MenuItem value="gathering">Gathering</MenuItem>
                <MenuItem value="ceremonial">Ceremonial</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Date"
                  value={formData.date}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Habitat Type"
                name="habitat"
                value={formData.habitat}
                onChange={handleChange}
                fullWidth
                margin="normal"
              >
                <MenuItem value="forest">Forest</MenuItem>
                <MenuItem value="river">River</MenuItem>
                <MenuItem value="meadow">Meadow</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Observation"
                name="observation"
                value={formData.observation}
                onChange={handleChange}
                fullWidth
                margin="normal"
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Species Collected"
                name="species"
                value={formData.species}
                onChange={handleChange}
                select
                fullWidth
                margin="normal"
              >
                <MenuItem value="flora">Flora</MenuItem>
                <MenuItem value="fauna">Fauna</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Quantity"
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
                fullWidth
                margin="normal"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Condition"
                name="condition"
                value={formData.condition}
                onChange={handleChange}
                multiline
                rows={2}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Submit
              </Button>
              <Button type="button" variant="outlined" color="secondary" fullWidth onClick={handleReset}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
