import { Button, Stack } from '@mui/material';
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/authActions';

const Toolbar = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout())
  }
  
  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Stack direction="row" width="100%" justifyContent="space-between" px={4}>
        <Navbar.Brand href="#home">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "100px", height: "10px", display: "table" }}>
              <svg
                preserveAspectRatio="xMidYMid meet"
                data-bbox="13.14 13.397 1840.33 1151.123"
                viewBox="13.14 13.397 1840.33 1151.123"
                xmlns="http://www.w3.org/2000/svg"
                data-type="color"
                role="presentation"
                aria-hidden="true"
                aria-label=""
              >
                <g>
                  <path
                    d="M1153.51 505.36c-24.25-139.72-119.11-255.96-238.19-328.7-118.62-69.95-268.5-81.81-398.18-37.14-25.45 8.49-49.67 19.97-73.37 32.5-71.49 36.9-130.88 95.28-174.83 162.36-42.41 64.71-70.47 138.83-78.41 216.07-3.37 31.07-3.66 62.46-1.14 93.66-5.36-30.94-7.53-62.59-6.18-94.2 4.81-127.92 66.8-250.76 160.32-337.1 29.39-27.27 63.1-50.22 98.9-68.6C492.1 117.55 546.55 100 602.35 92.07c103.54-14.83 211.91 3.25 303.86 53.46 129.49 75.01 234.42 207.5 247.31 359.81Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M1160.61 505.68C1110.2 257.35 868.89 64.63 613.64 100.89c-11.5 1.5-23.88 3.89-35.15 6.19-7.05 1.72-16.33 3.68-23.15 5.63-4.37 1.3-13.07 3.58-17.19 4.89-3.36 1.11-13.44 4.41-17 5.54-5.17 1.74-11.61 4.31-16.78 6.19-3.55 1.17-12.92 5.28-16.55 6.79-2.06.92-6.32 2.54-8.23 3.51-1.5.75-11.7 5.49-13.49 6.36-6.21 2.86-15.02 7.77-21.22 10.91-6.79 3.87-13.96 7.9-20.63 11.98-3.35 2.15-11.83 7.58-15.09 9.61-8.14 5.68-16.45 11.19-24.23 17.37l-9.49 7.24c-4.46 3.65-9.25 7.8-13.71 11.49-93.95 81.66-151.92 199.93-166.4 323.98-4.38 37.65-4.31 76.31.21 113.96 2.03 17.04 5.1 34.04 8.81 51.13-16.35-30.85-28.26-64.56-35.48-99.47-3.25-14.81-5.59-31.26-7.09-46.41-4.19-42.64-2.12-86.02 5.24-128.3 15.25-86.1 52.73-168.56 109.2-235.56 3.13-3.7 10.85-12.33 13.87-15.84 4.83-4.87 9.81-10.33 14.63-15.14 2.7-2.84 7.24-6.96 10.14-9.69 5.81-5.61 14.65-13.42 20.98-18.64 8.86-7.65 18.29-14.58 27.61-21.65 6.6-4.49 16.4-11.63 23.2-15.79 5.82-3.48 12.05-7.7 17.89-11.09 7.16-3.93 17.32-9.97 24.58-13.51l12.54-6.29c.82-.39 3.85-1.94 4.71-2.35 2.08-.99 6.07-2.69 8-3.59 2.04-.77 14.09-6.39 16.07-7.02 2.49-1.02 7.36-2.85 9.8-3.83 9.95-4.14 22.82-8.32 33.05-11.76 5.1-1.67 15.11-4.32 20.22-5.87 8.07-2.42 19.07-4.66 27.27-6.73 9.06-1.77 18.52-3.67 27.63-5.13 7.65-.95 20.21-3.13 27.92-3.72 279.24-31.84 555.99 204.47 544.27 489.42Z"
                    fill="#ed1c24"
                    data-color="2"
                  ></path>
                  <path
                    d="M1853.47 883.67C1171.93-40.1 13.13 812.66 13.14 812.66c413.53-34.11 759.29-119.28 1154.96-91.88 202.75 14.03 436.98 6.7 685.37 162.89Z"
                    fill="#ffffff"
                    data-color="3"
                  ></path>
                  <path
                    d="M37.31 917.1h43.31v98.41h112.62V917.1h43.32v247.41h-43.32v-111.58H80.62v111.58H37.31V917.1Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M286.42 917.1h43.32v247.41h-43.32V917.1Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M380.05 917.1h43.31v209.99h125.79v37.42h-169.1V917.1Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M575.24 917.1h43.31v209.99h125.79v37.42h-169.1V917.1Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M879.48 917.1h109.15c17.55 0 31.88 2.54 42.97 7.62s19.63 11.44 25.64 19.06c6 7.62 10.1 15.94 12.3 24.95 2.19 9.01 3.29 17.33 3.29 24.95s-1.1 15.89-3.29 24.78c-2.2 8.9-6.3 17.15-12.3 24.78-6.01 7.62-14.55 13.92-25.64 18.88-11.09 4.97-25.41 7.45-42.97 7.45h-65.84v94.95h-43.31V917.11Zm43.31 117.13h63.41c4.85 0 9.81-.69 14.9-2.08 5.08-1.39 9.76-3.64 14.04-6.76 4.27-3.12 7.74-7.28 10.39-12.47 2.65-5.2 3.99-11.72 3.99-19.58s-1.16-14.78-3.46-20.1c-2.31-5.31-5.43-9.53-9.36-12.65-3.93-3.12-8.44-5.25-13.51-6.41-5.08-1.15-10.52-1.73-16.29-1.73h-64.11v81.78Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M1102.53 917.1h43.31v209.99h125.79v37.42h-169.1V917.1Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M1379.74 917.1h46.09l95.29 247.41h-46.43l-23.22-65.49h-98.76l-23.22 65.49h-44.7l94.95-247.41Zm-15.25 149.01h75.54l-37.08-106.38h-1.04l-37.42 106.38Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M1544.4 917.1h43.32v247.41h-43.32V917.1Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M1637.27 917.1h45.74l112.97 182.27h.69V917.1h41.24v247.41h-45.74l-112.62-181.92h-1.04v181.92h-41.24V917.1Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M13.22 812.61S1217.53-93.08 1853.33 883.5c0 0-554.41-750.42-1840.11-70.89Z"
                    fill="#000000"
                    data-color="4"
                  ></path>
                  <path
                    d="M1853.33 883.55C1212.03 369.84 44.45 809.67 44.45 809.67c403.56-52.87 776.67-112.05 1164.77-98.04 198.87 7.18 466.73 69.56 644.11 171.92Z"
                    fill="#231f20"
                    data-color="1"
                  ></path>
                  <path
                    d="M1852.89 883.31c-643.47-474.83-1809.5-73.45-1809.5-73.45 404.35-8.74 786.44-70.75 1174.54-56.75 198.87 7.18 457.58 27.84 634.96 130.19Z"
                    fill="#fcee21"
                    data-color="5"
                  ></path>
                </g>
              </svg>
            </div>
            <div style={{ marginLeft: "10px" }}>Data collection Portal</div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {/* <Nav.Link href="#home">Home</Nav.Link> */}
            {/* <Nav.Link href="#link">Link</Nav.Link> */}
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          {/* <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-success">Search</Button>
          </Form> */}
        </Navbar.Collapse>
        <Button onClick={handleLogout}>Logout</Button>
      </Stack>
    </Navbar>
  );
};

export default Toolbar;
