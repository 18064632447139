import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import Toolbar from './Toolbar/Toolbar';

const ProtectedRoute = ({ children, withNavbar }) => {
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <>
      {withNavbar && <Toolbar />}
      <Outlet />
    </>
);
};

export default ProtectedRoute;
