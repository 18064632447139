import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import DataTable from '../components/Common/DataTable';
import { Card } from 'react-bootstrap';
import SearchComponent from '../components/Search/Search';
import DrawerComponent from '../components/Drawer/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { Draw, Modify, Snap } from "ol/interaction";
import TileLayer from 'ol/layer/Tile';
import { OSM } from 'ol/source';
import VectorLayer from 'ol/layer/Vector';
import { Map, Feature, View } from "ol";
import { fromLonLat, transform } from 'ol/proj';
import { setDrawerOpen, setSelectedCoordinates } from '../redux/actions/drawerActions';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import { notify } from '../utils/notify';


const vectorSource = new VectorSource();

const Home = () => {
  const mapRef = useRef(null);
  console.log("🚀 ~ $$$ ~ Home ~ mapRef:", mapRef)
  const dispatch = useDispatch();
  const [mapInitialized, setMapInitialized] = useState(false);
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated)


  useEffect(() => {
    if (isAuthenticated && !mapInitialized) {

      const initialMap = new Map({
        target: 'map',
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          new VectorLayer({
            source: vectorSource,
          }),
        ],
        view: new View({
          center: fromLonLat([-113.4909, 53.5461]),
          zoom: 8,
        }),
      });

      const draw = new Draw({
        source: vectorSource,
        type: 'Point',
      });

      initialMap.addInteraction(draw);
      initialMap.addInteraction(new Modify({ source: vectorSource }));
      initialMap.addInteraction(new Snap({ source: vectorSource }));

      const handleClick = (event) => {
        console.log('Map clicked');
        const coords = event.coordinate;
        const transformedCoords = transform(coords, 'EPSG:3857', 'EPSG:4326');
        const feature = new Feature({
          geometry: new Point(coords),
        });

        vectorSource.addFeature(feature);
        dispatch(setSelectedCoordinates(transformedCoords));
        dispatch(setDrawerOpen(true));

      };

      initialMap.on('click', handleClick);

      mapRef.current = initialMap;
      setMapInitialized(true);
    }
  }, [isAuthenticated, mapInitialized, vectorSource, dispatch]);

  const handleSearch = (event) => {
    const coordinates = event;
    if (mapRef.current && coordinates && coordinates.length === 2) {
      const [lon, lat] = coordinates;
      const center = fromLonLat([lon, lat]);
      const view = mapRef.current.getView();

      view.setCenter(center);
      view.setZoom(12);

      setTimeout(() => {
        mapRef.current.renderSync();
        mapRef.current.updateSize();
      }, 500);
    } else {
      console.error(
        "Map or coordinates are not available or invalid:",
        mapRef.current,
        coordinates
      );
    }
  };

  const handleSubmit = (formData) => {

  }

  const handleReset = () => {
    vectorSource.clear();
    dispatch(setSelectedCoordinates(null));
    dispatch(setDrawerOpen(false));
  };

  return (
    <>
      <div id="map" className="map"></div>
      <Dialog open="" onClose="" maxWidth="lg" fullWidth>
        <DialogTitle>Data Table</DialogTitle>
        <DialogContent>
          <DataTable />
        </DialogContent>
      </Dialog>
      <Card style={{ width: "18rem", top: "5em", left: "0.5em" }}>
        <Card.Body>
          <Card.Title>Search</Card.Title>
          <SearchComponent onSearch={handleSearch} />
        </Card.Body>
      </Card>
      <DrawerComponent
        isOpen=""
        onClose={handleReset}
        onSubmit={handleSubmit}
        onReset={handleReset}
      />
    </>
  );
}

export default Home